import { useEffect, useState } from 'react';
import './HomePage.scss';
import request from '../../request';
import { useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';

const HomePage = (props) => {
  const [orders, setOrders] = useState([]);

  const navigate = useNavigate();

  const key = useSelector((state) => state.app.key);

  useEffect(() => {
    request
      .get(`get-orders.php?limit=20&key=${key}`)
      .then((response) => {
        setOrders(response.data);
      })
      .catch((err) => {

      });
  }, []);

  return (
    <div className="HomePage">
      <table>
        <thead>
          <tr>
            <th>Όνομα</th>
            <th>Τηλέφωνο</th>
            <th>Συσκευή</th>
            <th>Σημείωση Service</th>
            <th>Ημ/νία</th>
          </tr>
        </thead>
        <tbody>
          {
            orders.map((order, i) => {
              return (
                <tr
                  key={i}
                  onClick={() => navigate(`/orders/${order.id}`)}
                >
                  <td>{order.firstname} {order.lastname}</td>
                  <td>{order.phone}</td>
                  <td>{order.brand_model}</td>
                  <td>{order.issue_description.slice(0, 150)}</td>
                  <td>{moment(order.date, 'YYYY-MM-DD').format('DD-MM-YYYY')}</td>
                </tr>
              );
            })
          }
        </tbody>
      </table>
    </div>
  );
};

export default HomePage;
