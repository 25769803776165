import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './OrderPage.scss';
import { Fragment, useEffect, useState } from 'react';
import moment from 'moment/moment';
import request from '../../request';
import { useNavigate, useParams } from 'react-router-dom';
import { faCircleNotch, faPrint, faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';

const OrderPage = (props) => {
  const [orderId, setOrderId] = useState(null);
  const [form, setForm] = useState({
    email: '',
    date: moment().format('YYYY-MM-DD'),
    lastname: '',
    firstname: '',
    address: '',
    city: '',
    zip: '',
    phone: '',
    phone2: '',
    brand_model: '',
    device_serial_code: '',
    delivered: [],
    device_username: '',
    device_password: '',
    device_pin: '',
    backup_required: '',
    issue_description: '',
    device_check: {},
  });
  const [deliveredOther, setDeliveredOther] = useState('');
  const [savingOrder, setSavingOrder] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [newTaskDescription, setNewTaskDescription] = useState('');
  const [newTaskQuantity, setNewTaskQuantity] = useState('');
  const [newTaskPrice, setNewTaskPrice] = useState('');

  const key = useSelector((state) => state.app.key);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (params.orderId) {
      request
        .get(`get-order.php?id=${params.orderId}&key=${key}`)
        .then((response) => {
          const order = response.data;

          setOrderId(order.id);
          
          const f = { ...form };
          f.email = order.email;
          f.date = order.date;
          f.lastname = order.lastname;
          f.firstname = order.firstname;
          f.address = order.address;
          f.city = order.city;
          f.zip = order.zip;
          f.phone = order.phone;
          f.phone2 = order.phone2;
          f.brand_model = order.brand_model;
          f.device_serial_code = order.device_serial_code;
          f.device_username = order.device_username;
          f.device_password = order.device_password;
          f.device_pin = order.device_pin;
          f.backup_required = order.backup_required;
          f.issue_description = order.issue_description;
          f.device_check = order.device_check;

          for (let i = 0; i < order.delivered.length; i++) {
            const standardValues = ["Φορτιστής", "Μπαταρία", "Τσάντα μεταφοράς", "Windows CD", "Άλλο"];
            
            if (!standardValues.includes(order.delivered[i])) {
              setDeliveredOther(order.delivered[i]);
              order.delivered.splice(i, 1);
              break;
            }
          }

          f.delivered = order.delivered;

          setForm({
            ...form,
            ...f,
          });

          setTasks(order.tasks);
        })
        .catch((err) => {

        });
    } else {
      setOrderId(null);
      setForm({
        email: '',
        date: moment().format('YYYY-MM-DD'),
        lastname: '',
        firstname: '',
        address: '',
        city: '',
        zip: '',
        phone: '',
        phone2: '',
        brand_model: '',
        device_serial_code: '',
        delivered: [],
        device_username: '',
        device_password: '',
        device_pin: '',
        backup_required: '',
        issue_description: '',
        device_check: {},
      });
      setTasks([]);
    }
  }, [params.orderId]);

  const onInputChange = (e) => {
    const f = { ...form };
    f[e.target.name] = e.target.value;

    setForm(f);
  };

  const onDeliveredCheckItem = (e) => {
    const d = form.delivered.slice();

    if (e.target.checked) {
      d.push(e.target.value);
    } else {
      d.splice(d.indexOf(e.target.value), 1);
    }

    setForm({
      ...form,
      delivered: d,
    });
  };

  const onDeviceCheckCheckboxChange = (e) => {
    const dc = { ...form.device_check };

    if (e.target.checked) {
      dc[e.target.name] = e.target.value;
      setForm({
        ...form,
        device_check: dc,
      })
    }
  };

  const save = () => {
    if (savingOrder) {
      return;
    }

    setSavingOrder(true);

    if (orderId) {
      request
        .post(`update-order.php?id=${orderId}&key=${key}`, {
          ...form,
          delivered: [
            ...form.delivered,
            deliveredOther,
          ],
          tasks,
        })
        .then((response) => {
          setSavingOrder(false);
          toast('Η παραλαβή αποθηκεύτηκε', {
            type: 'success',
          });
        })
        .catch((err) => {
          setSavingOrder(false);
        });
    } else {
      request
        .post(`new-order.php?key=${key}`, {
          ...form,
          delivered: [
            ...form.delivered,
            deliveredOther,
          ],
          tasks,
        })
        .then((response) => {
          setOrderId(response.data.order_id);
          setSavingOrder(false);
          toast('Η παραλαβή αποθηκεύτηκε', {
            type: 'success',
          });
        })
        .catch((err) => {
          setSavingOrder(false);
        });
    }
  };

  const deleteOrder = () => {
    if (window.confirm('Είστε βέβαιοι ότι θέλετε να διαγραφεί;')) {
      request
          .post(`delete-order.php?id=${orderId}&key=${key}`)
          .then((response) => {
            navigate('/dashboard');
          })
          .catch((err) => {
            
          });
    }
  };

  return (
    <div className="OrderPage">
      <div className="form">
        <div className="input">
          <span>Διεύθυνση ηλεκτρονικού ταχυδρομείου</span>
          <input
            type="text"
            name="email"
            value={form.email}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Ημερομηνία</span>
          <input
            type="date"
            name="date"
            value={form.date}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Επώνυμο</span>
          <input
            type="text"
            name="lastname"
            value={form.lastname}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Όνομα</span>
          <input
            type="text"
            name="firstname"
            value={form.firstname}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Διεύθυνση</span>
          <input
            type="text"
            name="address"
            value={form.address}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Πόλη</span>
          <input
            type="text"
            name="city"
            value={form.city}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Τ.Κ.</span>
          <input
            type="text"
            name="zip"
            value={form.zip}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Κινητό Τηλέφωνο</span>
          <input
            type="text"
            name="phone"
            value={form.phone}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Σταθερό Τηλέφωνο</span>
          <input
            type="text"
            name="phone2"
            value={form.phone2}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Μάρκα / Μοντέλο</span>
          <input
            type="text"
            name="brand_model"
            value={form.brand_model}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Serial Code</span>
          <input
            type="text"
            name="device_serial_code"
            value={form.device_serial_code}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Παραδίδονται μαζί</span>

          <div>
            <input type="checkbox" onChange={onDeliveredCheckItem} checked={form.delivered.includes('Φορτιστής')} value="Φορτιστής" />
            <label>Φορτιστής</label>
          </div>

          <div>
            <input type="checkbox" onChange={onDeliveredCheckItem} checked={form.delivered.includes('Μπαταρία')} value="Μπαταρία" />
            <label>Μπαταρία</label>
          </div>

          <div>
            <input type="checkbox" onChange={onDeliveredCheckItem} checked={form.delivered.includes('Τσάντα μεταφοράς')} value="Τσάντα μεταφοράς" />
            <label>Τσάντα μεταφοράς</label>
          </div>

          <div>
            <input type="checkbox" onChange={onDeliveredCheckItem} checked={form.delivered.includes('Windows CD')} value="Windows CD" />
            <label>Windows CD</label>
          </div>

          <div>
            <input type="checkbox" onChange={onDeliveredCheckItem} checked={form.delivered.includes('Άλλο')} value="Άλλο" />
            <label>Άλλο</label>
          </div>
          
          <input
            type="text"
            value={deliveredOther}
            onChange={(e) => {
              setDeliveredOther(e.target.value);
            }}
          />
        </div>

        <div className="input">
          <span>Όνομα Χρήστη (Windows ή Mac)</span>
          <input
            type="text"
            name="device_username"
            value={form.device_username}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Κωδικός Χρήστη (Windows ή Mac)</span>
          <input
            type="text"
            name="device_password"
            value={form.device_password}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Pin Συσκευής</span>
          <input
            type="text"
            name="device_pin"
            value={form.device_pin}
            onChange={onInputChange}
          />
        </div>

        <div className="input">
          <span>Backup δεδομένων</span>
          
          <div>
            <input type="radio" name="backup_required" value="0" checked={form.backup_required == '0'} onChange={onInputChange} />
            <label>Έχω backup των αρχείων μου</label>
          </div>

          <div>
            <input type="radio" name="backup_required" value="1" checked={form.backup_required == '1'} onChange={onInputChange} />
            <label>Παρακαλώ πάρτε εσείς backup των αρχείων μου (μπορεί να υπάρχει χρέωση)</label>
          </div>

          <div>
            <input type="radio" name="backup_required" value="-1" checked={form.backup_required == '-1'} onChange={onInputChange} />
            <label>Δεν χρειάζομαι backup</label>
          </div>
        </div>

        <div className="input">
          <span>Περιγραφή προβλήματος</span>
          <textarea
            name="issue_description"
            rows="3"
            value={form.issue_description}
            onChange={onInputChange}
          />
        </div>

        <div className="device-check">
          <span>Έλεγχος Συσκευής</span>

          <table>
            <thead>
              <tr>
                <th></th>
                <th>A</th>
                <th>B</th>
                <th>C</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Κατάσταση Οθόνης</td>
                <td>
                  <input type="checkbox" name="Κατάσταση Οθόνης" value="A" checked={form.device_check['Κατάσταση Οθόνης'] === 'A'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κατάσταση Οθόνης" value="B" checked={form.device_check['Κατάσταση Οθόνης'] === 'B'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κατάσταση Οθόνης" value="C" checked={form.device_check['Κατάσταση Οθόνης'] === 'C'} onChange={onDeviceCheckCheckboxChange} />
                </td>
              </tr>

              <tr>
                <td>Κατάσταση Πλαισίου</td>
                <td>
                  <input type="checkbox" name="Κατάσταση Πλαισίου" value="A" checked={form.device_check['Κατάσταση Πλαισίου'] === 'A'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κατάσταση Πλαισίου" value="B" checked={form.device_check['Κατάσταση Πλαισίου'] === 'B'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κατάσταση Πλαισίου" value="C" checked={form.device_check['Κατάσταση Πλαισίου'] === 'C'} onChange={onDeviceCheckCheckboxChange} />
                </td>
              </tr>

              <tr>
                <td>Πληκτρολόγια</td>
                <td>
                  <input type="checkbox" name="Πληκτρολόγια" value="A" checked={form.device_check['Πληκτρολόγια'] === 'A'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Πληκτρολόγια" value="B" checked={form.device_check['Πληκτρολόγια'] === 'B'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Πληκτρολόγια" value="C" checked={form.device_check['Πληκτρολόγια'] === 'C'} onChange={onDeviceCheckCheckboxChange} />
                </td>
              </tr>

              <tr>
                <td>Κάμερα Πίσω</td>
                <td>
                  <input type="checkbox" name="Κάμερα Πίσω" value="A" checked={form.device_check['Κάμερα Πίσω'] === 'A'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κάμερα Πίσω" value="B" checked={form.device_check['Κάμερα Πίσω'] === 'B'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κάμερα Πίσω" value="C" checked={form.device_check['Κάμερα Πίσω'] === 'C'} onChange={onDeviceCheckCheckboxChange} />
                </td>
              </tr>

              <tr>
                <td>Κάμερα Μπροστά</td>
                <td>
                  <input type="checkbox" name="Κάμερα Μπροστά" value="A" checked={form.device_check['Κάμερα Μπροστά'] === 'A'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κάμερα Μπροστά" value="B" checked={form.device_check['Κάμερα Μπροστά'] === 'B'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κάμερα Μπροστά" value="C" checked={form.device_check['Κάμερα Μπροστά'] === 'C'} onChange={onDeviceCheckCheckboxChange} />
                </td>
              </tr>

              <tr>
                <td>Μπαταρία</td>
                <td>
                  <input type="checkbox" name="Μπαταρία" value="A" checked={form.device_check['Μπαταρία'] === 'A'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Μπαταρία" value="B" checked={form.device_check['Μπαταρία'] === 'B'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Μπαταρία" value="C" checked={form.device_check['Μπαταρία'] === 'C'} onChange={onDeviceCheckCheckboxChange} />
                </td>
              </tr>

              <tr>
                <td>Κουμπιά</td>
                <td>
                  <input type="checkbox" name="Κουμπιά" value="A" checked={form.device_check['Κουμπιά'] === 'A'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κουμπιά" value="B" checked={form.device_check['Κουμπιά'] === 'B'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Κουμπιά" value="C" checked={form.device_check['Κουμπιά'] === 'C'} onChange={onDeviceCheckCheckboxChange} />
                </td>
              </tr>

              <tr>
                <td>Connector</td>
                <td>
                  <input type="checkbox" name="Connector" value="A" checked={form.device_check['Connector'] === 'A'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Connector" value="B" checked={form.device_check['Connector'] === 'B'} onChange={onDeviceCheckCheckboxChange} />
                </td>
                <td>
                  <input type="checkbox" name="Connector" value="C" checked={form.device_check['Connector'] === 'C'} onChange={onDeviceCheckCheckboxChange} />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="tasks no-print">
          <span>Εργασίες</span>
          <table>
            <tbody>
              {
                tasks.map((task, i) => {
                  return (
                    <tr
                      key={i}
                    >
                      <td>
                        <textarea value={task.description} onChange={(e) => {
                          const t = tasks.slice();
                          t[i].description = e.target.value;
                          
                          setTasks(t);
                        }}></textarea>
                      </td>
                      <td>
                        <input type="text" value={task.quantity} onChange={(e) => {
                          const t = tasks.slice();
                          t[i].quantity = e.target.value;
                          
                          setTasks(t);
                        }} />
                      </td>
                      <td style={{ position: 'relative' }}>
                        <span style={{
                          position: 'absolute',
                          top: '50%',
                          right: '10px',
                          transform: 'translate(0%, -50%)',
                        }}>€</span>
                        <input type="text" style={{ paddingRight: '30px' }} value={task.price} onChange={(e) => {
                          const t = tasks.slice();
                          t[i].price = e.target.value;
                          
                          setTasks(t);
                        }} />
                      </td>
                      <td style={{ padding: '10px' }}><FontAwesomeIcon icon={faTimes} onClick={() => {
                        const t = tasks.slice();
                        
                        t.splice(i, 1);
                        setTasks(t);
                      }}></FontAwesomeIcon></td>
                    </tr>
                  )
                })
              }
              <tr>
                <td>
                  <textarea
                    type="text"
                    placeholder="Ειδος - Εργασία"
                    value={newTaskDescription}
                    onChange={(e) => setNewTaskDescription(e.target.value)}
                    onBlur={() => {
                      if (newTaskDescription.length > 0) {
                        const t = tasks.slice();
                        
                        t.push({
                          description: newTaskDescription,
                          quantity: newTaskQuantity,
                          price: newTaskPrice,
                        });
                        setTasks(t);
                        setNewTaskDescription('');
                        setNewTaskQuantity('');
                        setNewTaskPrice('');

                        setTimeout(() => {
                          Array.from(document.querySelectorAll('.tasks table tr')).slice(-2)[0].querySelector('input').focus();
                        });
                      }
                    }}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Ποσότητα"
                    value={newTaskQuantity}
                    onChange={(e) => setNewTaskQuantity(e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    placeholder="Κόστος"
                    value={newTaskPrice}
                    onChange={(e) => setNewTaskPrice(e.target.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="terms only-print">
          <div>
            <span className="title">Χρόνος Επισκευής</span>
            <p>
              Ο χρόνος έναρξης και λήξης της επισκευής εξαρτάται από την διαθεσιμότητα των τεχνικών και των ανταλλακτικών και κυμαίνεται από 2 έως 30 εργάσιμες ημέρες.

              Εγγύηση: Η εγγύηση καλύπτει τις εσωτερικές βλάβες που προκλήθηκαν από την κανονική χρήση της συσκευής (σύμφωνα με τις οδηγίες του κατασκευαστή).

              Η εγγύηση δεν καλύπτει: Βλάβες που οφείλονται σε κακή χρήση ή χρήση της συσκευής για εμπορικούς σκοπούς, ή τη χρήση των προϊόντων σε δυσμενείς συνθήκες (υγρασία, ακαθαρσία, σκόνη, βρωμιά, τρίχες, σκουριά, οξείδωση, χτυπήματα, κραδασμούς, απότομη αλλαγή θερμοκρασίας κ.λπ.) ή σε φυσιολογική φθορά λόγω χρήσης.
            </p>
          </div>

          <div>
            <span className="title">Η εγγύηση δεν καλύπτει</span>
            <p>
              Επιδιόρθωση ή αποκατάσταση βλάβης που έχει προκληθεί από επισκευαστές μη εξουσιοδοτημένους από την κατασκευάστρια εταιρεία.

              Επιδιόρθωση ή αποκατάσταση βλάβης που έχει προκληθεί από κακή χρήση του προϊόντος ή μη σωστή συντήρηση του σύμφωνα με τις οδηγίες χρήσης που το συνοδεύουν (συμπεριλαμβανομένου του χειρισμού της μπαταρίας και την χρήση φορτιστή).

              Επιδιόρθωση ή αποκατάσταση βλάβης που έχει προκληθεί από την χρήση λογισμικού διαφορετικού από εκείνο που συνοδεύει το προϊόν (όπως π.χ. η εγκατάσταση ιών ή κακόβουλου λογισμικού). Σε αυτή την περίπτωση η διάγνωση του προβλήματος και η επαναφορά του προϊόντος στην κατάσταση εργοστασιακής ρύθμισης επιβαρύνεται οικονομικά από τον πελάτη. Έχει τη δυνατότητα να επιλέξει αν επιθυμεί την παραλαβή της συσκευής του χωρίς να γίνει καμία επέμβαση.
            </p>
          </div>

          <div>
            <span className="title">Ευρωπαϊκός Κανονισμός Γενικής Προστασίας Δεδομένων (GDPR)</span>
            <p>
              Η συμμόρφωση μας με τον Κανονισμό είναι καθοριστικής σημασίας για την ασφαλεία των προσωπικών σας δεδομένων. Με τη συμπλήρωση αυτής της φόρμας δηλώνετε ότι αποδέχεστε την επεξεργασία των προσωπικών σας δεδομένων για τους σκοπούς που αναφέρονται στη σύμβαση παροχής υπηρεσιών και τη διαδικασία επισκευής. Για περισσότερες πληροφορίες σχετικά με την επεξεργασία των προσωπικών σας δεδομένων μπορείτε να ανατρέξετε στην Πολιτική Απορρήτου της Google.
              Αλλαγές: Οι αλλαγές σας θα πραγματοποιηθούν κατάλληλα μέσα στα πλαίσια της ασφαλείας και της ιδιωτικότητας που παρέχει ο Κανονισμός.
              Διαγραφή: Για την άσκηση των δικαιωμάτων σας μπορείτε να επικοινωνήσετε με την αρμόδια Αρχή Προστασίας Δεδομένων Προσωπικού Χαρακτήρα ή να επικοινωνήσετε απευθείας με την εταιρεία μας με ηλεκτρονικό ταχυδρομείο στη διεύθυνση: dataprotection@google.com
            </p>
          </div>
        </div>

        <div className="w-100 d-flex flex-directon-row justify-content-between mt-2 no-print">
          <div className="d-flex flex-direction-row" style={{ gap: '20px' }}>
            <button className="button" onClick={save}>
              {
                savingOrder
                  ? <Fragment>
                      <FontAwesomeIcon icon={faCircleNotch} spin></FontAwesomeIcon>
                      &nbsp;
                      <span>Γίνεται αποθήκευση</span>
                    </Fragment>
                  : 'Αποθήκευση'
              }
            </button>

            <button className={`btn-print button button-default${!orderId ? ' d-none' : ''}`} onClick={(() => window.print())}><FontAwesomeIcon icon={faPrint}></FontAwesomeIcon>&nbsp;Εκτύπωση</button>
          </div>

          <button className={`${!orderId ? 'd-none' : ''}`} onClick={deleteOrder}>Διαγραφή</button>
        </div>
      </div>
    </div>
  );
};

export default OrderPage;
