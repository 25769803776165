import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SearchBar.scss';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import request from '../../request';
import moment from 'moment/moment';
import { useSelector } from 'react-redux';

const SearchBar = (props) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [previousSearches, setPreviousSearches] = useState([]);

  const key = useSelector((state) => state.app.key);

  const elementRef = useRef(null);
  const searchTimeout = useRef(null);

  const navigate = useNavigate();

  useEffect(() => {
    const localStoredPreviousSearches = localStorage.getItem('previous-searches');

    if (localStoredPreviousSearches) {
      setPreviousSearches(JSON.parse(localStoredPreviousSearches));
    }


  }, []);

  useEffect(() => {
    clearTimeout(searchTimeout.current);

    if (searchQuery.length > 0) {
      request
        .get(`search.php?q=${searchQuery}&key=${key}`)
        .then((response) => {
          const results = response.data;

          setSearchResults(results);
        })
        .catch((err) => {

        });
    } else {
      setSearchResults(previousSearches);
    }
  }, [searchQuery]);

  useEffect(() => {
    localStorage.setItem('previous-searches', JSON.stringify(previousSearches));
  }, [previousSearches]);

  return (
    <div
      ref={elementRef}
      className={`SearchBar${searchResults.length > 0 ? ' open' : ''}`}
    >
      <FontAwesomeIcon icon={faSearch} className="icon"></FontAwesomeIcon>

      <span
        className="placeholder"
        onClick={() => {
          elementRef.current.querySelector('input').focus();
        }}
      >
        Αναζήτηση
      </span>

      <input
        type="text"
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        onFocus={() => elementRef.current.querySelector('.placeholder').classList.add('d-none')}
        onBlur={() => {
          if (searchQuery.length === 0) {
            elementRef.current.querySelector('.placeholder').classList.remove('d-none');
          }
        }}
      />

      <div className={`results`}>
        {
          searchResults.map((result, i) => {
            return (
              <div
                key={i}
                onClick={() => {
                  navigate(`/orders/${result.id}`);
                  setSearchQuery('');
                  setSearchResults([]);
                }}
              >
                {result.firstname} {result.lastname}, {result.brand_model} - {moment(result.date).format('DD-MM-YYYY')}
              </div>
            );  
          })
        }  
      </div>
    </div>
  );
};

export default SearchBar;