import './App.scss';
import './overrides.scss';
import { ToastContainer, Slide, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import 'moment/locale/el';
import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';
import HomePage from './components/home-page/HomePage';
import Header from './components/header/Header';
import OrderPage from './components/order-page/OrderPage';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faBackspace, faCircle, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';
import request from './request';
import Index from './components/index/Index';
import { useSelector } from 'react-redux';
import store from './store';
import appSlice from './store/app/appSlice';

moment.locale('el');

function App() {
  const [checkedLoginStatus, setCheckedLoginStatus] = useState(false);
  const [showPinPad, setShowPinPad] = useState(false);
  const [code, setCode] = useState('');

  const navigate = useNavigate();

  const key = useSelector((state) => state.app.key);

  useEffect(() => {
    request
      .get(`check-login-status.php?key=${key}`)
      .then((response) => {
        setCheckedLoginStatus(true);
        navigate('/dashboard');
      })
      .catch((err) => {
        if (err.response.status === 401) {
          navigate('/');
          setShowPinPad(true);
        }
      });
  }, []);

  return (
    <div className="App">
      
        <Header></Header>

        <main>
          <Routes>
            <Route exact path="/" element={<Index />}></Route>
            <Route exact path="/dashboard" element={<HomePage />}></Route>
            <Route path="/new-order" element={<OrderPage></OrderPage>}></Route>
            <Route path="/orders/:orderId" element={<OrderPage></OrderPage>}></Route>
          </Routes>
        </main>

        <div className={`login-backdrop${checkedLoginStatus ? ' d-none' : ''}`}>
          <FontAwesomeIcon icon={faCircleNotch} spin size="2x"></FontAwesomeIcon>
        </div>

        <div className={`pin-pad${showPinPad ? '' : ' d-none'}`}>
          <div className="code">
            {
              Array.from(Array(code.length)).map((n, i) => {
                return (
                  <FontAwesomeIcon icon={faCircle} style={{ margin: '0 10px' }}></FontAwesomeIcon>
                );
              })
            }
          </div>
          <div className="pad">
            {
              [1, 2, 3, 4, 5, 6, 7, 8, 9, <FontAwesomeIcon icon={faBackspace}></FontAwesomeIcon>, 0, <FontAwesomeIcon icon={faArrowRight}></FontAwesomeIcon>].map((option, i) => {
                return (
                  <div
                    key={i}
                    onClick={() => {
                      if (typeof option === 'number') {
                        setCode(code + option);
                      }
                      
                      if (i === 9) {
                        setCode(code.slice(0, -1));
                      }

                      if (i === 11) {
                        setCode('');
                        
                        request
                          .get(`login.php?code=${code}`)
                          .then((response) => {
                            setCheckedLoginStatus(true);
                            setShowPinPad(false);
                            store.dispatch(appSlice.actions.setKey(response.data));
                            navigate('/dashboard');
                          })
                          .catch((err) => {
                            
                          });
                      }
                    }}
                  >
                    {option}
                  </div>
                );
              })
            }
          </div>
        </div>

        <ToastContainer
          position='bottom-center'
          transition={Slide}
        ></ToastContainer>
      
    </div>
  );
}

export default App;
