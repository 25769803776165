import './Header.scss';
import logo from '../../assets/img/fix-masters-logo.png';
import SearchBar from '../search-bar/SearchBar';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const Header = (props) => {
  return (
    <div className="Header no-print">
      <Link to="/dashboard" className="brand">
        <img src={logo} height="40" />
        <span>Back Office</span>
      </Link>

      <SearchBar></SearchBar>

      <div className="right">
        <Link to="/new-order"><FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>&nbsp;Νέα Παραλαβή</Link>
      </div>
    </div>
  );
};

export default Header;
