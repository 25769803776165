import { createSlice } from "@reduxjs/toolkit";

const appSlice = createSlice({
  name: 'app',
  initialState: {
    key: '',
  },
  reducers: {
    setKey: (state, action) => {
      state.key = action.payload;
    },
  },
});

export default appSlice;
